import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | 24Hr Diesel Repair",
    pageDescription:
      "See how an automotive shop leverages SpotOn to streamline payment processing and get loan deposits sooner than with their previous provider.",
    title: "Financing and payment processing for an automotive shop",
    subTitle: `How 24Hr Diesel Repair gets funding and takes payments faster with SpotOn.`,
    businessName: "24Hr Diesel Repair",
    industry: "Automotive, auto repair",
    location: "Atlanta, Georgia",
    products: [
      spotonProducts.CAPITAL,
      spotonProducts.RETAIL,
    ],
    description:
      "With two locations and 8 employees, 24Hr Diesel Repair is a busy automotive shop that has seen sales double in the past year. And with SpotOn technology, owners Brett Fuller and Tony Hawkins can take advantage of quick payment processing and loan approvals to drive a more efficient repair operation.",
    quoteData: {
      imgName: "24hr-diesel-repair.png",
      quote: `“If you’re considering SpotOn Capital, I recommend it. You know what fees you get charged, it’s simple and easy. SpotOn is a great company to work with.”`,
      personName: "Brett Fuller",
      personTitle: "Owner, 24Hr Diesel Repair",
    },
    goal: `Brett Fuller, co-owner of 24Hr diesel, needed a platform that could quickly process customer payments. He was also looking for a partner that could provide him with loans for expensive parts without holding large deposits for up to 2 weeks.`,
    solution: `With SpotOn, Fuller can offer customers flexible payment options and maintain cash flow from one dashboard. And with SpotOn Capital, he can pay loans with a percentage of his daily sales while deposits show up faster in his bank account.`,
    results: {
      title: `The results`,
      stats: [
        { 
          title: `5 – 10 hours`, 
          description: `saved on weekly admin` 
        },
        {
          title: `5+ days`,
          description: `faster deposit arrivals`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Getting more regulars with rewards",
        imageName: "strows-dry-cleaners.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-27",
        href: "/case-studies/strows-dry-cleaners",
        goals: [
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
      title: "Growing a flower business from the ground up",
      imageName: "flowers-on-phoenix.png",
      industry: resourceIndustries.RETAIL,
      tag: resourceTags.CASE_STUDY,
      date: "2022-07-28",
      href: "/case-studies/flowers-on-phoenix",
      goals: [
        resourceGoals.STREAMLINE_OPERATIONS,
        resourceGoals.GET_SMARTER_DATA,
        resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        resourceGoals.BUILD_CUSTOMER_LOYALTY,
      ],
    },
    {
        title: "A blooming nursery gives customers a faster experience",
        imageName: "unique-plants-and-palms.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-13",
        href: "/case-studies/unique-plants-and-palms",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
  ];
  